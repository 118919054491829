import React, { useState } from 'react';
import './industrial.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import rojo1 from '../catIndustrial/007 POLIURETANO BOTA INDUSTRIAL 1.JPG';
import rojo2 from '../catIndustrial/007 POLIURETANO BOTA INDUSTRIAL 2.JPG';
import rojo3 from '../catIndustrial/007 POLIURETANO BOTA INDUSTRIAL 3.JPG';
import CAPRINOS1 from '../catIndustrial/206 CAPRINOS BOTA INDUSTRIAL CALZADO RENDER 1.JPG';
import CAPRINOS2 from '../catIndustrial/206 CAPRINOS BOTA INDUSTRIAL CALZADO RENDER 2.JPG';
import CAPRINOS3 from '../catIndustrial/206 CAPRINOS BOTA INDUSTRIAL CALZADO RENDER 3.JPG';
import PUNTERA1 from '../catIndustrial/206 ELASTOMERO BOTA INDUSTRIAL CALZADO RENDER 1.JPG';
import PUNTERA2 from '../catIndustrial/206 ELASTOMERO BOTA INDUSTRIAL CALZADO RENDER 2.JPG';
import PUNTERA3 from '../catIndustrial/SUELA ELASTOMERO CALZADO RENDER.JPG';
import MOLE1 from '../catIndustrial/603 LAMOLE BOTA INDUSTRIAL 1.jpg';
import MOLE2 from '../catIndustrial/603 LAMOLE BOTA INDUSTRIAL 2.jpg';
import MOLE3 from '../catIndustrial/SUELA LA MOLE CALZADO RENDER.jpg';
import POWER1 from '../catIndustrial/206 POWER BOTA INDUSTRIAL CALZADO RENDER 1.jpg';
import POWER2 from '../catIndustrial/206 POWER BOTA INDUSTRIAL CALZADO RENDER 2.jpg';
import POWER3 from '../catIndustrial/206 POWER BOTA INDUSTRIAL CALZADO RENDER 3.jpg';
import POWER4 from '../catIndustrial/SUELA POWER CALZADO RENDER.jpg';
import TOSCANA1 from '../catIndustrial/206 TOSCANA BOTA INDUSTRIAL CALZADO RENDER 1.JPG';
import TOSCANA2 from '../catIndustrial/206 TOSCANA BOTA INDUSTRIAL CALZADO RENDER 2.JPG';
import TOSCANA3 from '../catIndustrial/SUELA TOSCANA BOTA INDUSTRIAL CALZADO RENDER.JPG';
import TPUCAFE1 from '../catIndustrial/206 TPU BOTA INDUSTRIAL CALZADO RENDER 1.jpg';
import TPUCAFE2 from '../catIndustrial/206 TPU BOTA INDUSTRIAL CALZADO RENDER 3.jpg';
import TPUCAFE3 from '../catIndustrial/SUELA TPU CALZADO RENDER.jpg';
import TPUHUMO1 from '../catIndustrial/606 2002 BOTA INDUSTRIAL CALZADO RENDER 1.jpg';
import TPUHUMO2 from '../catIndustrial/606 2002 BOTA INDUSTRIAL CALZADO RENDER 2.jpg';
import TPUHUMO3 from '../catIndustrial/SUELA 2002 BOTA INDUSTRIAL CALZADO RENDER.jpg';
import ULTRON1 from '../catIndustrial/206 ULTRON BOTA INDUSTRIAL CALZADO RENDER 1.jpg';
import ULTRON2 from '../catIndustrial/206 ULTRON BOTA INDUSTRIAL CALZADO RENDER 2.jpg';
import ULTRON3 from '../catIndustrial/SUELA ULTRON BOTA INDUSTRIAL CALZADO RENDER.jpg';
import MX1 from '../catIndustrial/207 MX BOTA INDUSTRIAL CALZADO RENDER 1.jpg';
import MX2 from '../catIndustrial/207 MX BOTA INDUSTRIAL CALZADO RENDER 3.jpg';
import MX3 from '../catIndustrial/SUELA MX BOTA INDUSTRIAL CALZADO RENDER.jpg';
import HERCULES1505 from '../catIndustrial/505 HERCULES CHOCLO INDUSTRIAL CALZADO RENDER 1.JPG';
import HERCULES2505 from '../catIndustrial/505 HERCULES CHOCLO INDUSTRIAL CALZADO RENDER 2.JPG';
import HERCULES3505 from '../catIndustrial/SUELA HERCULES ZAPATO INDUSTRIAL CALZADO RENDER 1.JPG';
import HERCULES1 from '../catIndustrial/600 HERCULES CHOCLO INDUSTRIAL CALZADO RENDER 1.JPG';
import HERCULES2 from '../catIndustrial/600 HERCULES CHOCLO INDUSTRIAL CALZADO RENDER 2.JPG';
import HERCULES3 from '../catIndustrial/SUELA HERCULES ZAPATO INDUSTRIAL CALZADO RENDER 2.JPG';
import CAPRINOS1603 from '../catIndustrial/603 CAPRINOS BOTA INDUSTRIAL CALZADO RENDER 1.JPG';
import CAPRINOS2603 from '../catIndustrial/603 CAPRINOS BOTA INDUSTRIAL CALZADO RENDER 2.JPG';
import CAPRINOS3603 from '../catIndustrial/SUELA CAPRINOS BOTA INDUSTRIAL CALZADO RENDER.JPG';
import FREE1 from '../catIndustrial/600 FREE CHOCLO CALZADO RENDER 2.JPG';
import FREE2 from '../catIndustrial/600 FREE CHOCLO INDUSTRIAL CALZADO RENDER 1.JPG';
import FREE3 from '../catIndustrial/SUELA FREE CALZADO RENDER.JPG';
import HERCULES1603 from '../catIndustrial/603 HERCULES BOTA INDUSTRIAL CALZADO RENDER 1.jpg';
import HERCULES2603 from '../catIndustrial/603HERCULES BOTA INDUSTRIAL CALZADO RENDER 3.jpg';
import HERCULES3603 from '../catIndustrial/SUELA HERCULES BOTA INDUSTRIAL CALZADO RENDER.jpg';
import TOSCANA1603 from '../catIndustrial/603 TOSCANA BOTA INDUSTRIAL CALZADO RENDER 1.jpg';
import TOSCANA2603 from '../catIndustrial/603 TOSCANA BOTA INDUSTRIAL CALZADO RENDER 3.jpg';
import TOSCANA3603 from '../catIndustrial/SUELA TOSCANA BOTA INDUSTRIAL CALZADO RENDER2.jpg';
import TPU1 from '../catIndustrial/603 TPU BOTA INDUSTRIAL CALZADO RENDER 1.jpg';
import TPU2 from '../catIndustrial/603 TPU BOTA INDUSTRIAL CALZADO RENDER 3.jpg';
import TPU3 from '../catIndustrial/SUELA TPU CALZADO RENDER1.jpg';
import BALDER1 from '../catIndustrial/604 BALDER BOTA USO RUDO CALZADO RENDER 1.JPG';
import BALDER2 from '../catIndustrial/604 BALDER BOTA USO RUDO CALZADO RENDER 2.JPG';
import BALDER3 from '../catIndustrial/SUELA BALDER BOTA USO RUDO CALZADO RENDER 1.JPG';
import MX1625 from '../catIndustrial/625 MX BOTA TACTICA CALZADO RENDER 1.JPG';
import MX2625 from '../catIndustrial/625 MX BOTA TACTICA CALZADO RENDER 2.JPG';
import MX3625 from '../catIndustrial/SUELA MX BOTA INDUSTRIAL CALZADO RENDER3.JPG';
import MX1705 from '../catIndustrial/705-MX1.JPG';
import MX2705 from '../catIndustrial/705-MX2.JPG';
import MX3705 from '../catIndustrial/705-MX3.JPG';
import FREE1750 from '../catIndustrial/750-FREE1.JPG';
import FREE2750 from '../catIndustrial/750-FREE2.JPG';
import FREE3750 from '../catIndustrial/750-FREE3.JPG';
import MX1780 from '../catIndustrial/780-MX1.JPG';
import MX2780 from '../catIndustrial/780-MX2.JPG';
import MX3780 from '../catIndustrial/780-MX3.JPG';
import FREEROPER1 from '../catIndustrial/780-ROPER-FREE1.JPG';
import FREEROPER2 from '../catIndustrial/780-ROPER-FREE2.JPG';
import FREEROPER3 from '../catIndustrial/780-ROPER-FREE3.JPG';
import CardDeck from 'react-bootstrap/CardDeck';
import Producto from './Producto';
import MuestraImg from './MuestraImg';
import Indust from '../industrial/INDUSTRIALENCABEZADO.png';
import Redess from '../components/Redess';


const CatIndustrial = () => {
    const [nomontadas, montadas] = useState([
        {index:1, imgN:[rojo1,rojo2,rojo3], name:'007-ROJO-TOSCANA', desc:'Modelo 007: Borceguí Dieléctrico Con Casco De Poliamida En Combinación De Piel Y Lona De Alta Resistencia Y Transpiración Bullón En Tubo Con Terminación En Corte Diagonal Y Ajuste De Látigo Con Velcro. Plantilla De Poliuretano De 7mm Removible. Suela Toscana: Suela De Material Poliuretano Doble Densidad Con Puntera, Antiderrapante  Ligero Y Flexible Sistema Dieléctrico, Anti fatiga, Ergonómica, Última Generación, Amortiguación.'},
        {index:2, imgN:[CAPRINOS1,CAPRINOS2,CAPRINOS3], name:'206-CAPRINOS', desc:'Modelo 206: Borceguí Corte Premium, Dieléctrico En Piel Con Casco De Poliamida Y Con Bullón Y Lengüeta  Piel De Cerdo,  Tubo Con Terminación En Corte Diagonal. Plantilla De Poliuretano De 7mm RemovibleSuela Caprinos: Suela Con Cuña De Poliuretano Y Patín Acrilo Nitrilo Color Azul, Sistema Dieléctrica, Anti fatiga, Ergonómica, Ultima Generación, Amortiguación En Toda Su Superficie Para Alto Confort E Impactos, Diseño Exclusivo, Resistente A Grasas, Ácidos Y Aceites, Tracción Inversa, Antiderrapante, Alta Ligereza Y Confort.'},
        {index:3, imgN:[PUNTERA1,PUNTERA2,PUNTERA3], name:'206-ELASTOMERO-PUNTERA', desc:'Modelo 206: Borceguí Corte Premium, Dieléctrico En Piel Con Casco De Poliamida Y Con Bullón Y Lengüeta  Piel De Cerdo,  Tubo Con Terminación En Corte Diagonal. Plantilla De Poliuretano De 7mm Removible. Suela Elastómero: Suela De Elastómero Doble Densidad Y Doble Material Última Generación. Cuya De Poliuretano Y Patín De Elastómero, Con Amortiguación En Toda La Suela Anti fatiga, Ergonómica, Sistema Dieléctrica. Resistente A Grasas, Solventes, Ácidos, Aceites Y Temperaturas Medias. Tracción Inversa, Antiderrapante. Máxima Ligereza, Antiderrapancia Y Confort.'},
        {index:4, imgN:[MOLE2,MOLE3,MOLE1], name:'206- SUELA LAMOLE', desc:'Modelo 206: Borceguí Corte Premium, Dieléctrico En Piel Con Casco De Poliamida Y Con Bullón Y Lengüeta  Piel De Cerdo,  Tubo Con Terminación En Corte Diagonal. Plantilla De Poliuretano De 7mm Removible. Suela Lamole: Suela De Poliuretano De Doble Densidad Con Cuña De Pu. De Densidad Suave Para Gran Confort Y Ligereza Y Todo El Patín En Pu. Sistema Dieléctrico, De Densidad Compacta Para Alta Duración Y Máxima Antiderrapancia.'}
    ]);
    const [nomotadas, motadas] = useState([
        {index:1, imgN:[POWER1,POWER2,POWER4,POWER2], name:'206-SUELA POWER', desc:'Modelo 206: Borceguí Corte Premium, Dieléctrico En Piel Con Casco De Poliamida Y Con Bullón Y Lengüeta  Piel De Cerdo,  Tubo Con Terminación En Corte Diagonal. Plantilla De Poliuretano De 7mm Removible. Suela Power: Suela Con Cuña Y Patín poliuretano Compacto Color Amarillo Doble Densidad, Sistema Dieléctrico, Anti fatiga, Ergonómica, Última Generación, Amortiguación En Toda Su Superficie Para Alto Confort E Impactos, Diseño Exclusivo, Diseño Con Puntera,  Resistente A Grasas, Aceites Tracción Inversa, Alta Ligereza Y Confort Para Altas Jornadas. '},
        {index:2, imgN:[TOSCANA1,TOSCANA2,TOSCANA3], name:'206-TOSCANA', desc:'Modelo 206: Borceguí Corte Premium, Dieléctrico En Piel Con Casco De Poliamida Y Con Bullón Y Lengüeta  Piel De Cerdo,  Tubo Con Terminación En Corte Diagonal. Plantilla De Poliuretano De 7mm Removible Suela Toscana: Suela De Material Poliuretano Doble Densidad Con Puntera, Antiderrapante  Ligero Y Flexible Sistema Dieléctrico, Anti fatiga, Ergonómica, Última Generación, Amortiguación.'},
        {index:3, imgN:[TPUCAFE1,TPUCAFE2,TPUCAFE3], name:'206-SUELA TPU', desc:'Modelo 206: Borceguí Corte Premium, Dieléctrico En Piel Con Casco De Poliamida Y Con Bullón Y Lengüeta  Piel De Cerdo,  Tubo Con Terminación En Corte Diagonal. Plantilla De Poliuretano De 7mm Removible. Suela T.P.U.: Suela Iron Man Doble Densidad Y Doble Material, Sistema Dieléctrica Aislante Al Calor/Frio, Anti fatiga, Ergonómica, Ultima Generación, Amortiguación En Toda Su Superficie Para Alto Confort E Impactos, Diseño Exclusivo, Flexible No Se Parte. Material De Suela: Cuya Poliuretano Y Patín Termo Plastic Uretano (T.P.U.) Resistente A Grasas, Aceites Y Solventes, Tracción Inversa, Alta Resistencia A La Abrasión. Alta Ligereza Y Confort.'},
        {index:4, imgN:[TPUHUMO1,TPUHUMO2,TPUHUMO3], name:'606-SUELA 2002', desc:'Modelo 606: Borceguí Dieléctrico Fabricado En Piel Con Casco De Poliamida, Bullón Ergonomic-Fit En Piel De Cerdo. Plantilla De Poliuretano De 7mm Removible. Suela 2002: Suela De Hule Con Acrilo Nitrilo Al 30% Sistema Dieléctrico, Flexible, Resistente A Grasas, Aceites, Solventes, Y Temperaturas Medias, Resistente A Agentes Punzo Cortantes, Alta Resistencia Y Durabilidad'}
    ]);
    const [nmotadas, mtadas] = useState([
        {index:1, imgN:[ULTRON1,ULTRON2,ULTRON3], name:'206-SUELA ULTRON', desc:'Modelo 206: Borceguí Corte Premium, Dieléctrico En Piel Con Casco De Poliamida Y Con Bullón Y Lengüeta  Piel De Cerdo,  Tubo Con Terminación En Corte Diagonal. Plantilla De Poliuretano De 7mm Removible. Suela Ultron: Suela De Poliuretano Doble Densidad, Sistema Dieléctrico, Anti fatiga, Ergonómica, Ultima Generación, Amortiguación En Toda Su Superficie Para Alto Confort E Impactos, Diseño Exclusivo,  Material De Suela: Cuña Poliuretano Y Patin Poliuretano Compacto Color Amarillo, Resistente A Grasas, Aceites Tracción Inversa, Alta Ligereza Y Confort.'},
        {index:2, imgN:[MX1,MX2,MX3], name:'207-MX', desc:'Modelo 207: Borceguí Económico En Piel-Mex, Dieléctrico Con Casco De Poliamida, Bullón Forrado En Piel Sintética Y Lengüeta De Carnaza. Plantilla De Poliuretano De 7mm Removible. Suela Mx: Suela De Última Generación De Hule Con Acrilo Nitrilo Resistente A La Abrasión, Grasas, Y Algunos Solventes, Sistema Dieléctrico, Amortiguación En El Talón, Arco Y Planta Del Pie Para Absorción De Altos, Medianos Y Bajos Impactos Diseño Exclusivo, Flexible No Se Parte, Resistente A Grasas, Aceites , Tracción Inversa, Ultra Antiderrapancia, Ligereza Y Confort.'},
        {index:3, imgN:[HERCULES1505,HERCULES2505,HERCULES3505], name:'505-HERCULES', desc:'Modelo 505: Choclo Tipo Tenis En Combinación Piel Y Maya, Dieléctrico Con Casco De Poliamida. Plantilla De Poliuretano De 7mm Removible. Suela Hércules: Suela  De Poliuretano Doble Densidad, Sistema Dieléctrico, Antiderrapante, Anti fatiga, Ergonómica, Última Generación, Amortiguación En Toda Su Superficie Para Alto Confort E Impactos, Diseño Exclusivo, Flexible No Se Parte. Material De Suela: Cuya Poliuretano Y Patín, Poliuretano Compacto Color Rojo, Resistente A Grasas, Aceites, Tracción Inversa, Alta Ligereza Y Confort.'},
        {index:4, imgN:[HERCULES1,HERCULES2,HERCULES3], name:'600-HERCULES', desc:'Modelo 600: Calzado Tipo Choclo Fabricado En Piel, Dieléctrico Con Casco De Poliamida. Plantilla De Poliuretano De 7mm Removible. Suela Hércules: Suela  De Poliuretano Doble Densidad, Sistema Dieléctrico, Antiderrapante, Anti fatiga, Ergonómica, Última Generación, Amortiguación En Toda Su Superficie Para Alto Confort E Impactos, Diseño Exclusivo, Flexible No Se Parte. Material De Suela: Cuya Poliuretano Y Patín, Poliuretano Compacto Color Rojo, Resistente A Grasas, Aceites, Tracción Inversa, Alta Ligereza Y Confort.'}
    ]);
    const [otadas, tadas] = useState([
        {index:1, imgN:[CAPRINOS1603,CAPRINOS2603,CAPRINOS3603], name:'603-CAPRINOS', desc:'Modelo 603: Borceguí Dieléctrico Fabricado En Piel Con Casco De Poliamida, Bullón Dividido En Piel De Cerdo. Plantilla De Poliuretano De 7mm Removible. Suela Caprinos: Suela Con Cuña De Poliuretano Y Patín Acrilo, Nitrilo Color Azul, Sistema Dieléctrica, Anti fatiga, Ergonómica, Ultima Generación, Amortiguación En Toda Su Superficie Para Alto Confort E Impactos, Diseño Exclusivo, Resistente A Grasas, Ácidos Y Aceites, Tracción, Inversa, Antiderrapante, Alta Ligereza Y Confort.'},
        {index:2, imgN:[FREE1,FREE2,FREE3], name:'603-FREE', desc:'Modelo 600: Calzado Tipo Choclo Fabricado En Piel, Dieléctrico Con Casco De Poliamida. Plantilla De Poliuretano De 7mm Removible. Suela Free: Suela De Material De Hule Con Acrilo Nitrilo, Sistema Dieléctrico, Diseño Exclusivo, Flexible No Se Parte. Resistente A Grasas, Solventes, Ácidos, Aceites Y Temperaturas. Tracción Inversa, Antiderrapante.'},
        {index:3, imgN:[HERCULES1603,HERCULES2603,HERCULES3603], name:'603-SUELA HERCULES', desc:'Modelo 603: Borceguí Dieléctrico Fabricado En Piel Con Casco De Poliamida, Bullón Dividido En Piel De Cerdo. Plantilla De Poliuretano De 7mm Removible. Suela Hércules: Suela  De Poliuretano Doble Densidad, Sistema Dieléctrico, Antiderrapante, Anti fatiga, Ergonómica, Última Generación, Amortiguación, En Toda Su Superficie Para Alto Confort E Impactos, Diseño Exclusivo, Flexible No Se Parte. Material De Suela: Cuya Poliuretano Y Patín, Poliuretano Compacto Color Rojo, Resistente A Grasas, Aceites, Tracción Inversa, Alta Ligereza Y Confort.'},
        {index:4, imgN:[TOSCANA1603,TOSCANA2603,TOSCANA3603], name:'603-TOSCANA', desc:'Modelo 603: Borceguí Dieléctrico Fabricado En Piel Con Casco De Poliamida, Bullón Dividido En Piel De Cerdo. Plantilla De Poliuretano De 7mm Removible. Suela Toscana: Suela De Material Poliuretano Doble Densidad Con Puntera, Antiderrapante  Ligero Y Flexible Sistema Dieléctrico, Anti fatiga, Ergonómica, Última Generación, Amortiguación.'}
    ]);
    const [utadas, adas] = useState([
        {index:1, imgN:[TPU1,TPU2,TPU3], name:'603-SUELA TPU', desc:'Modelo 603: Borceguí Dieléctrico Fabricado En Piel Con Casco De Poliamida, Bullón Dividido En Piel De Cerdo. Plantilla De Poliuretano De 7mm Removible. Suela T.P.U.: Suela Iron Man Doble Densidad Y Doble Material, Sistema Dieléctrica, Aislante Al Calor/Frio, Anti fatiga, Ergonómica, Ultima Generación, Amortiguación En Toda Su Superficie Para Alto Confort E Impactos, Diseño Exclusivo, Flexible No Se Parte. Material De Suela: Cuya Poliuretano Y Patín Termo Plastic Uretano (T.P.U.) Resistente A Grasas, Aceites Y Solventes, Tracción Inversa, Alta Resistencia A La Abrasión. Alta Ligereza Y Confort.'},
        {index:2, imgN:[BALDER1,BALDER2,BALDER3], name:'604-BALDER', desc:'Modelo 604: Borceguí Con Sistema Waterproof, Evita Entrada De Polvo Y Líquidos, Ideal Para Uso Rudo Y Aéreas Especiales.  Dieléctrico Con Casco De Poliamida. Plantilla De Poliuretano De 7mm Removible. Suela Balder: Suela De Material De Cuña De Poliuretano Ligero Con Piso De Hule, Sistema Dieléctrico Tracción Inversa, Resistente A La Abrasión, Aceites, Altas Temperaturas. Alta Ligereza Y Confort, Uso Rudo Y Alta Resistencia Y Durabilida'},
        {index:3, imgN:[MX1625,MX2625,MX3625], name:'625-MX', desc:'Modelo 625: Bota Táctica En Piel Con Cierre Lateral, Altura De 22 Centímetros Con 10 Ojillos Y Lengüeta En Carnaza. Plantilla De Poliuretano De 7mm Removible. Suela Mx: Suela De Última Generación De Hule Con Acrilo Nitrilo Resistente A La Abrasión, Grasas, Y Algunos Solventes, Sistema Dieléctrico, Amortiguación En El Talón, Arco Y Planta Del Pie Para Absorción De Altos, Medianos Y Bajos Impactos Diseño Exclusivo, Flexible No Se Parte, Resistente A Grasas, Aceites , Tracción Inversa, Ultra Antiderrapancia, Ligereza Y Confort.'},
        {index:4, imgN:[MX1705,MX2705,MX3705], name:'705-MX', desc:'Aquí va la descripción'}
    ]);
    const [etadas, cadas] = useState([
        {index:1, imgN:[FREE1750,FREE2750,FREE3750], name:'750-FREE', desc:'Aquí va la descripción'},
        {index:2, imgN:[MX1780,MX2780,MX3780], name:'780-MX', desc:'Aquí va la descripción'},
        {index:3, imgN:[FREEROPER1,FREEROPER2,FREEROPER3], name:'780-ROPER-FREE', desc:'Aquí va la descripción'},
        {index:4, imgN:[MX1705,MX2705,MX3705], name:'705-MX', desc:'Aquí va la descripción'}
    ]);
    
    const [showImg, notShowImg] = useState([]);

    const seleccionarImg = index => {
    const imagn = nomontadas.filter(imagn => imagn.index === index)[0];
    montadas(imagn);
    };

    return (
        <div className="App">
            <Redess />
            <img src={Indust} alt="Industrial" /><br /><br />
            <CardDeck className="catalocard">
            {nomontadas.map(producto => (
            <Producto 
            key={producto.id}
            producto={producto}
            nomontadas={nomontadas}
            showImg={showImg}
            notShowImg={notShowImg}
            />
            ))}
            </CardDeck>
            <br /><br />
            <MuestraImg 
            showImg={showImg}
            />
            <CardDeck className="catalocard">
            {nomotadas.map(producto => (
            <Producto 
            key={producto.id}
            producto={producto}
            nomotadas={nomotadas}
            />
            ))}
            </CardDeck>
            <br /><br />
            <CardDeck className="catalocard">
            {nmotadas.map(producto => (
            <Producto 
            key={producto.id}
            producto={producto}
            nmotadas={nmotadas}
            />
            ))}
            </CardDeck>
            <br /><br />
            <CardDeck className="catalocard">
            {otadas.map(producto => (
            <Producto 
            key={producto.id}
            producto={producto}
            otadas={otadas}
            />
            ))}
            </CardDeck>
            <br /><br />
            <CardDeck className="catalocard">
            {utadas.map(producto => (
            <Producto 
            key={producto.id}
            producto={producto}
            utadas={utadas}
            />
            ))}
            </CardDeck>
            <br /><br />
            <CardDeck className="catalocard">
            {etadas.map(producto => (
            <Producto 
            key={producto.id}
            producto={producto}
            etadas={etadas}
            />
            ))}
            </CardDeck>
            <br /><br />
        </div>
    );
}

export default CatIndustrial;