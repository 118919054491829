import React from 'react';
import './App.css';
import Header from './components/Header';
import Industrial from './components/Industrial';
import Render from './components/Render';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Footer from './components/Footer';
import Antiestatico from './components/Antiestatico';
import Contacto from './components/Contacto';
import CatIndustrial from './components/CatIndustrial';
import CatDielectrico from './components/CatDielectrico';
import CatAntiestatico from './components/CatAntiestatico';
import Gracias from './components/Gracias';

export default function App() {

  const fecha = new Date().getFullYear();

  return (
    <Router>
      <div className="App">
      <Header />
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
        <Route path="/Gracias">
            <Graciase />
        </Route>
        <Route path="/CatAntiestatico">
            <CatAntiestatic />
          </Route>
        <Route path="/CatDielectrico">
            <CatDielec />
          </Route>
          <Route path="/CatIndustrial">
            <CatIndus />
          </Route>
          <Route path="/Contacto">
            <Contact />
          </Route>
          <Route path="/Industrial">
            <About />
          </Route>
          <Route path="/Antiestatico">
            <Users />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      <Footer 
      fecha={fecha}
      />
      </div>
    </Router>
  );
}

function Home() {
  window.scrollTo(0, 0);
  return <Render />;
}

function About() {
  window.scrollTo(0, 0);
  return <Industrial />;
}

function Users() {
  window.scrollTo(0, 0);
  return <Antiestatico />;
}

function Contact() {
  window.scrollTo(0, 0);
  return <Contacto />;
}

function CatIndus() {
  window.scrollTo(0, 0);
  return <CatIndustrial />;
}
function CatDielec() {
  window.scrollTo(0, 0);
  return <CatDielectrico />;
}
function CatAntiestatic() {
  window.scrollTo(0, 0);
  return <CatAntiestatico />;
}
function Graciase() {
  window.scrollTo(0, 0);
  return <Gracias />;
}