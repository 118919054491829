import React from 'react';
import './industrial.css';
import Card from 'react-bootstrap/Card';
import ESD from '../antiestatico/esd.png';
import AntiestImg1 from '../antiestatico/antiestatic.png';
import AntiestImg from '../industrial/antiestaimg.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Redess from '../components/Redess';

const Antiestatico = () => {
    return (
        <div className="App">
            <Redess />
            <img src={AntiestImg1} alt="Antiestático" />
            <br /><br />
            <Card body>Los zapatos de seguridad ANTIESTÁTICOS tienen una baja resistencia eléctrica entre 0.1 y 1000 MegaOhmios (MΩ).
            El uso de zapatos de seguridad antiestáticos evita la acumulación de cargas eléctricas estáticas
            en el cuerpo humano al enviar estas cargas al suelo, evitando un flujo repentino de electricidad entre objetos cargados
            eléctricamente causado por el contacto.</Card>
            <br /><br />
            <iframe class='videosre' width="560" height="315" src="https://www.youtube.com/embed/6qvous1Hc7Q?autoplay=1&mute=1&enable" frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; autoplay;" allowFullScreen title='Render Antiestatico' /><br /><br />
            <Container>
                <Row>
                    <Col><img id="antiestimg" src={AntiestImg} alt="antiestatica" /></Col>
                    <Col><span>Seguridad Antiestatica</span><br /><br />
                        <div>Un Calzado antiestático es aquel incapaz de retener una carga eléctrica significativa cuando
                        está conectado a tierra, lo que permite que la carga se disipe a tierra a través de él.
                        Así cuando se habla de calzado o ropa antiestática, se hace referencia a equipos con
                        propiedades disipativas, es decir, conductoras dentro de unos márgenes.</div>
                    </Col>
                </Row>
            </Container>
            <br /><br />
            <Card>
                <Card.Img variant="top" src={ESD} />
                <Card.Body>
                    <Card.Text>
                        Con las siglas ESD (Electro Static Discharge) que su significado es “Descarga Electrostática”.
                        <br /><br />
                        Algunas propiedades de la electricidad estática causan problemas y el calzado se ha convertido en un componente importante que proporciona control electrostático en algunas áreas de trabajo.
                        <br /><br />
                        El control de la electricidad estática no deseada es de vital importancia cuando el personal trabaja cerca de procesos,
                        objetos o materiales sensibles a descargas estáticas, como en la producción o el uso de componentes electrónicos.
                        Se puede proporcionar conexión a tierra para el personal a través de sus pies mientras están de pie o caminando sobre
                        una superficie debidamente establecida para el control electrostático. Por eso, cuando se utiliza calzado ESD,
                        se debe recordar que el calzado no puede brindar protección por sí solo, pero también es necesaria una superficie ESD adecuada.
                    </Card.Text>
                </Card.Body>
            </Card>
            <br />
        </div>
    );
}

export default Antiestatico;