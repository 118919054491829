import React from 'react';
import { useState } from 'react';
import './industrial.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Container, FormControl } from 'react-bootstrap';
import Mape from './Mape';
import emailjs from 'emailjs-com';
import Modal from 'react-bootstrap/Modal'
import Redess from '../components/Redess';

const Contacto = () => {
    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_777bamx', 'template_q0w4898', e.target, 'user_oRmjNlTesD8N94pthB39Y')
            .then((result) => {
                console.log(result.text);
                document.getElementById("fr1").reset();
                yesClick();
            }, (error) => {
                console.log(error.text);
            });
    }
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const modalclose = () => setTimeout(function (handleShow) {
        handleClose();
    }, 5000);
    function yesClick() {
        gotoHome();
        // handleShow();
        // modalclose();
     }
     function gotoHome() {
        window.location.href = "/Gracias";
     }
    return (
        <div id="contactor">
            <Redess />
            <Container className='containcto'>
                <br />
                <Form id='fr1' onSubmit={sendEmail}>
                    <Form.Group>
                        <Form.Label><h4>{'Contáctanos'}</h4></Form.Label>
                        <Form.Control id='tb1' type="text" placeholder="Nombre" required name="from_name" /><br />
                        <Form.Control id='emaail' type="email" placeholder="Correo Electrónico" required name="email" /><br />
                        <Form.Control id='tb2' type="number" placeholder="Teléfono" required name="tel" /><br />
                        <Form.Control id='tb3' as="textarea" rows="3" placeholder="Escribenos tus dudas, preguntas o comentarios..." required name="message" />
                        <Form.Text className="text-muted">
                            Suscribete para recibir promociones y nuevas noticias.
            </Form.Text>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Enviar
            </Button>
                </Form><br />
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header>
                        <Modal.Title>Mensaje Enviado Exitosamente</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Pronto nos estaremos poniendo en contacto.</Modal.Body>
                </Modal>
                <div>
                    <h4>{'Calzado Render'}</h4>
                    <a href="mailto:ventas@calzadorender.com">{'ventas@calzadorender.com'}</a><br />
                    <a href="tel:3331219915">{'Teléfono: 33 31 21 99 15'}</a><br />
                    <a href="https://goo.gl/maps/LhCtXaiUU2mTQfSd8" id='direccionren'>{'San Juan de La Cruz 637, Camino Real, 45040 Zapopan, Jal'}</a>
                </div>
                <br />
            </Container>
            <div className='centraGoogle'>
                <Mape />
            </div>
        </div>
    );
}

export default Contacto;