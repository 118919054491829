import React, { useState } from 'react';
import '../App.css';
import logo from '../logo.png';
import logo3 from '../logo3.png';
import carousel1 from '../carousel/banner1.png';
import carousel2 from '../carousel/banner2.jpg';
import carousel3 from '../carousel/banner3.png';
import carousel4 from '../carousel/banner4.png';
import carousel5 from '../carousel/banner5.png';
import carousel6 from '../carousel/banner6.png';
import indeximg from '../carousel/index.png';
import antiestatic from '../categopictures/ANTIESTATICOcate.png';
import dielectric from '../categopictures/DIELECTRICOcate.png';
import industri from '../categopictures/INDUSTRIALcate.png';
import honda from '../brands/honda.png';
import continental from '../brands/continental.png';
import pinsa from '../brands/pinsa.png';
import iusa from '../brands/iusa.png';
import ibm from '../brands/ibm.png';
import ingredion from '../brands/ingredion.png';
import jabil from '../brands/jabil.png';
import panasonic from '../brands/panasonic.png';
import abc from '../brands/abc.png';
import armasel from '../brands/armasel.png';
import estafeta from '../brands/estafeta.png';
import grupomar from '../brands/grupomar.png';
import verde from '../brands/verde.png';
import nippon from '../brands/nippon.png';
import esmeralda from '../brands/esmeralda.png';
import chedraui from '../brands/chedraui.png';
import yakult from '../brands/yakult.png';
//import Nav from 'react-bootstrap/Nav';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import CardDeck from 'react-bootstrap/CardDeck';
//import CardColumns from 'react-bootstrap/CardColumns';
import CardGroup from 'react-bootstrap/CardGroup';
import 'bootstrap/dist/css/bootstrap.min.css';
import Producto from './Producto';
import rojo1 from '../catIndustrial/007 POLIURETANO BOTA INDUSTRIAL 1.JPG';
import rojo2 from '../catIndustrial/007 POLIURETANO BOTA INDUSTRIAL 2.JPG';
import rojo3 from '../catIndustrial/007 POLIURETANO BOTA INDUSTRIAL 3.JPG';
import CAPRINOS1 from '../catIndustrial/206 CAPRINOS BOTA INDUSTRIAL CALZADO RENDER 1.JPG';
import CAPRINOS2 from '../catIndustrial/206 CAPRINOS BOTA INDUSTRIAL CALZADO RENDER 2.JPG';
import CAPRINOS3 from '../catIndustrial/206 CAPRINOS BOTA INDUSTRIAL CALZADO RENDER 3.JPG';
import PUNTERA1 from '../catIndustrial/206 ELASTOMERO BOTA INDUSTRIAL CALZADO RENDER 1.JPG';
import PUNTERA2 from '../catIndustrial/206 ELASTOMERO BOTA INDUSTRIAL CALZADO RENDER 2.JPG';
import PUNTERA3 from '../catIndustrial/SUELA ELASTOMERO CALZADO RENDER.JPG';
import MOLE1 from '../catIndustrial/603 LAMOLE BOTA INDUSTRIAL 1.jpg';
import MOLE2 from '../catIndustrial/603 LAMOLE BOTA INDUSTRIAL 2.jpg';
import MOLE3 from '../catIndustrial/SUELA LA MOLE CALZADO RENDER.jpg';
import POWER1 from '../catIndustrial/206 POWER BOTA INDUSTRIAL CALZADO RENDER 1.jpg';
import POWER2 from '../catIndustrial/206 POWER BOTA INDUSTRIAL CALZADO RENDER 2.jpg';
import POWER3 from '../catIndustrial/206 POWER BOTA INDUSTRIAL CALZADO RENDER 3.jpg';
import POWER4 from '../catIndustrial/SUELA POWER CALZADO RENDER.jpg';
import TOSCANA1 from '../catIndustrial/206 TOSCANA BOTA INDUSTRIAL CALZADO RENDER 1.JPG';
import TOSCANA2 from '../catIndustrial/206 TOSCANA BOTA INDUSTRIAL CALZADO RENDER 2.JPG';
import TOSCANA3 from '../catIndustrial/SUELA TOSCANA BOTA INDUSTRIAL CALZADO RENDER.JPG';
import TPUCAFE1 from '../catIndustrial/206 TPU BOTA INDUSTRIAL CALZADO RENDER 1.jpg';
import TPUCAFE2 from '../catIndustrial/206 TPU BOTA INDUSTRIAL CALZADO RENDER 3.jpg';
import TPUCAFE3 from '../catIndustrial/SUELA TPU CALZADO RENDER.jpg';
import TPUHUMO1 from '../catIndustrial/606 2002 BOTA INDUSTRIAL CALZADO RENDER 1.jpg';
import TPUHUMO2 from '../catIndustrial/606 2002 BOTA INDUSTRIAL CALZADO RENDER 2.jpg';
import TPUHUMO3 from '../catIndustrial/SUELA 2002 BOTA INDUSTRIAL CALZADO RENDER.jpg';
import psd1 from '../antiestatico/717-PSD1.jpg';
import psd2 from '../antiestatico/717-PSD2.jpg';
import psd3 from '../antiestatico/717-PSD3.jpg';
import sd71 from '../antiestatico/717-SD1.jpg';
import sd72 from '../antiestatico/717-SD2.jpg';
import sd73 from '../antiestatico/717-SD3.jpg';
import psd201 from '../antiestatico/720-PSD1.JPG';
import psd202 from '../antiestatico/720-PSD2.JPG';
import psd203 from '../antiestatico/720-PSD3.JPG';
import sd201 from '../antiestatico/720-SD1.jpg';
import sd202 from '../antiestatico/720-SD2.jpg';
import sd203 from '../antiestatico/720-SD3.jpg';
import Redess from '../components/Redess';
import { Link } from 'react-router-dom';

const Render = () => {

  const [nomontadas, montadas] = useState([
        {index:1, imgN:[rojo1,rojo2,rojo3], name:'007-ROJO-TOSCANA', desc:'Modelo 007: Borceguí Dieléctrico Con Casco De Poliamida En Combinación De Piel Y Lona De Alta Resistencia Y Transpiración Bullón En Tubo Con Terminación En Corte Diagonal Y Ajuste De Látigo Con Velcro. Plantilla De Poliuretano De 7mm Removible. Suela Toscana: Suela De Material Poliuretano Doble Densidad Con Puntera, Antiderrapante  Ligero Y Flexible Sistema Dieléctrico, Anti fatiga, Ergonómica, Última Generación, Amortiguación.'},
        {index:2, imgN:[CAPRINOS1,CAPRINOS2,CAPRINOS3], name:'206-CAPRINOS', desc:'Modelo 206: Borceguí Corte Premium, Dieléctrico En Piel Con Casco De Poliamida Y Con Bullón Y Lengüeta  Piel De Cerdo,  Tubo Con Terminación En Corte Diagonal. Plantilla De Poliuretano De 7mm RemovibleSuela Caprinos: Suela Con Cuña De Poliuretano Y Patín Acrilo Nitrilo Color Azul, Sistema Dieléctrica, Anti fatiga, Ergonómica, Ultima Generación, Amortiguación En Toda Su Superficie Para Alto Confort E Impactos, Diseño Exclusivo, Resistente A Grasas, Ácidos Y Aceites, Tracción Inversa, Antiderrapante, Alta Ligereza Y Confort.'},
        {index:3, imgN:[PUNTERA1,PUNTERA2,PUNTERA3], name:'206-ELASTOMERO-PUNTERA', desc:'Modelo 206: Borceguí Corte Premium, Dieléctrico En Piel Con Casco De Poliamida Y Con Bullón Y Lengüeta  Piel De Cerdo,  Tubo Con Terminación En Corte Diagonal. Plantilla De Poliuretano De 7mm Removible. Suela Elastómero: Suela De Elastómero Doble Densidad Y Doble Material Última Generación. Cuya De Poliuretano Y Patín De Elastómero, Con Amortiguación En Toda La Suela Anti fatiga, Ergonómica, Sistema Dieléctrica. Resistente A Grasas, Solventes, Ácidos, Aceites Y Temperaturas Medias. Tracción Inversa, Antiderrapante. Máxima Ligereza, Antiderrapancia Y Confort.'},
        {index:4, imgN:[MOLE2,MOLE3,MOLE1], name:'206- SUELA LAMOLE', desc:'Modelo 206: Borceguí Corte Premium, Dieléctrico En Piel Con Casco De Poliamida Y Con Bullón Y Lengüeta  Piel De Cerdo,  Tubo Con Terminación En Corte Diagonal. Plantilla De Poliuretano De 7mm Removible. Suela Lamole: Suela De Poliuretano De Doble Densidad Con Cuña De Pu. De Densidad Suave Para Gran Confort Y Ligereza Y Todo El Patín En Pu. Sistema Dieléctrico, De Densidad Compacta Para Alta Duración Y Máxima Antiderrapancia.'}
    ]);
  const [nomotadas, motadas] = useState([
        {index:1, imgN:[POWER1,POWER2,POWER4,POWER2], name:'206-SUELA POWER', desc:'Modelo 206: Borceguí Corte Premium, Dieléctrico En Piel Con Casco De Poliamida Y Con Bullón Y Lengüeta  Piel De Cerdo,  Tubo Con Terminación En Corte Diagonal. Plantilla De Poliuretano De 7mm Removible. Suela Power: Suela Con Cuña Y Patín poliuretano Compacto Color Amarillo Doble Densidad, Sistema Dieléctrico, Anti fatiga, Ergonómica, Última Generación, Amortiguación En Toda Su Superficie Para Alto Confort E Impactos, Diseño Exclusivo, Diseño Con Puntera,  Resistente A Grasas, Aceites Tracción Inversa, Alta Ligereza Y Confort Para Altas Jornadas. '},
        {index:2, imgN:[TOSCANA1,TOSCANA2,TOSCANA3], name:'206-TOSCANA', desc:'Modelo 206: Borceguí Corte Premium, Dieléctrico En Piel Con Casco De Poliamida Y Con Bullón Y Lengüeta  Piel De Cerdo,  Tubo Con Terminación En Corte Diagonal. Plantilla De Poliuretano De 7mm Removible Suela Toscana: Suela De Material Poliuretano Doble Densidad Con Puntera, Antiderrapante  Ligero Y Flexible Sistema Dieléctrico, Anti fatiga, Ergonómica, Última Generación, Amortiguación.'},
        {index:3, imgN:[TPUCAFE1,TPUCAFE2,TPUCAFE3], name:'206-SUELA TPU', desc:'Modelo 206: Borceguí Corte Premium, Dieléctrico En Piel Con Casco De Poliamida Y Con Bullón Y Lengüeta  Piel De Cerdo,  Tubo Con Terminación En Corte Diagonal. Plantilla De Poliuretano De 7mm Removible. Suela T.P.U.: Suela Iron Man Doble Densidad Y Doble Material, Sistema Dieléctrica Aislante Al Calor/Frio, Anti fatiga, Ergonómica, Ultima Generación, Amortiguación En Toda Su Superficie Para Alto Confort E Impactos, Diseño Exclusivo, Flexible No Se Parte. Material De Suela: Cuya Poliuretano Y Patín Termo Plastic Uretano (T.P.U.) Resistente A Grasas, Aceites Y Solventes, Tracción Inversa, Alta Resistencia A La Abrasión. Alta Ligereza Y Confort.'},
        {index:4, imgN:[TPUHUMO1,TPUHUMO2,TPUHUMO3], name:'606-SUELA 2002', desc:'Modelo 606: Borceguí Dieléctrico Fabricado En Piel Con Casco De Poliamida, Bullón Ergonomic-Fit En Piel De Cerdo. Plantilla De Poliuretano De 7mm Removible. Suela 2002: Suela De Hule Con Acrilo Nitrilo Al 30% Sistema Dieléctrico, Flexible, Resistente A Grasas, Aceites, Solventes, Y Temperaturas Medias, Resistente A Agentes Punzo Cortantes, Alta Resistencia Y Durabilidad'}
     ]);
  const [nmotadas, mtadas] = useState([
    { index: 1, imgN: [psd1, psd2, psd3], name: '717-PSD', desc: 'El modelo 717 PSD, es un calzado antiestático tipo tenis. Elaborado con piel engrasada de gran resistencia y repelente al agua, cuenta con un diseño ergonómico que proporciona gran seguridad y comodidad al usuario. Esto convierte al 717 en el modelo perfecto para las electrónicas con condiciones de trabajo exigentes.' },
    { index: 2, imgN: [sd71, sd72, sd73], name: '717-SD', desc: 'Aquí va la descripción' },
    { index: 3, imgN: [psd201, psd202, psd203], name: '720-PSD', desc: 'Aquí va la descripción' },
    { index: 4, imgN: [sd201, sd202, sd203], name: '720-SD', desc: 'Aquí va la descripción' }
  ]);

  const [showImg, notShowImg] = useState([]);

  return (
    <div className="App">
      <Carousel interval="5000" pause="false">
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={carousel1}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={carousel2}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={carousel3}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={carousel4}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={carousel5}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={carousel6}
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
      <CardDeck>
        <Card className="categtrecard">
        <Link to="/Catindustrial">
          <Card.Img variant="top" src={industri} />
          </Link>
          <Card.Body>
            <Card.Title>Industrial</Card.Title>
            <Card.Text>
              Calzado de seguridad pensado para todo tipo de industria
              y se encarga de proporcionar un nivel de protección superior hasta en las condiciones más exigentes.
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <a className="categtre" href="/CatIndustrial">
              <small className="text-muted">Ir a esta categoría</small>
            </a>
          </Card.Footer>
        </Card>
        <Card className="categtrecard">
        <Link to="/CatAntiestatico">
          <Card.Img variant="top" src={antiestatic} />
          </Link>
          <Card.Body>
            <Card.Title>Antiestático</Card.Title>
            <Card.Text>
              Somos especialistas en calzado con propiedades ESD para aterrizar a las personas,
              ideal para uso en electrónicas y en condiciones específicas.
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <a className="categtre" href="/CatAntiestatico">
              <small className="text-muted">Ir a esta categoría</small>
            </a>
          </Card.Footer>
        </Card>
        <Card className="categtrecard">
        <Link to="/CatDielectrico">
          <Card.Img variant="top" src={dielectric} />
          </Link>
          <Card.Body>
            <Card.Title>Dieléctrico</Card.Title>
            <Card.Text>
              Para nosotros tu seguridad es lo más importante,
              contamos con gran variedad de modelos, pieles y suelas de excelente calidad con el mejor precio del mercado.
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <a className="categtre" href="/CatDielectrico">
              <small className="text-muted">Ir a esta categoría</small>
            </a>
          </Card.Footer>
        </Card>
      </CardDeck>
      <header>
        <img src={indeximg} className="indeximg" alt="logo" /> 
        <Redess />
      </header>
      <div className="render">
        <div>
          <img src={logo} className="logoSize2" alt="logo" />
          <br />
          <br />
          <img src={logo3} className="" alt="logo" />
        </div>
        <div id="media" className="justificado">
          <hr />
          <h5>RENDER</h5>
          <hr />
          <p>
            Render es una empresa mexicana dedicada a la fabricación y distribución de calzado de seguridad a nivel nacional. Con más de 15 años de experiencia y respaldados por una lista de clientes integrada por empresas como Nissan, Honda, IBM, Grupo Pinza, Ingredion, Panasonic, Huntsman, entre muchos otros.<br />

Lo que nos ha consolidado en el mercado mexicano como una de las mejores opciones de calzado de seguridad y antiestático.<br />

Además, nos diferenciamos por siempre brindar productos de la más alta tecnología, con un precio por debajo de la competencia y un servicio caracterizado por una excelente atención y enfocado a las necesidades de cada cliente.<br />

A lo largo de estos más de 15 años hemos logrado incrementar el nivel de producción y distribución de manera exponencial debido al interés de los clientes por nuestro producto, permitiéndonos desarrollar nuevas tecnologías enfocadas a la seguridad de los usuarios, así como contar con un alcance de distribución nacional.
         </p>
        </div>
      </div>
      <div className="myv">
        <div id="mision" className="justificado">
          <h5>MISIÓN</h5>
          <p>
            Proveer productos de excelente calidad a nuestros clientes con la más alta

            tecnología en materia de seguridad antiestática e industrial. Asimismo,

            brindar siempre el mejor servicio y una atención enfocada en las

            necesidades específicas de cada uno de nuestros clientes para así elevar

            su nivel de productividad.
         </p>
        </div>
        <div id="vision" className="justificado">
          <h5>VISIÓN</h5>
          <p>
            Consolidarnos como referente en el mercado mexicano, como la empresa
            líder en la industria del calzado de seguridad antiestática e industrial
         </p>
        </div>
      </div>
      <br />
      <h3 id="productoD">PRODUCTO DESTACADO</h3>
      <Carousel slide="false" id="productoDest">
        <Carousel.Item>
          <CardDeck>
            {nomotadas.map(producto => (
              <Producto
                key={producto.id}
                producto={producto}
                nomotadas={nomotadas}
              />
            ))}
          </CardDeck>
        </Carousel.Item>
        <Carousel.Item>
          <CardDeck>
            {nomontadas.map(producto => (
              <Producto
                key={producto.id}
                producto={producto}
                nomontadas={nomontadas}
                showImg={showImg}
                notShowImg={notShowImg}
              />
            ))}
          </CardDeck>
        </Carousel.Item>
        <Carousel.Item>
          <CardDeck>
            {nmotadas.map(producto => (
              <Producto
                key={producto.id}
                producto={producto}
                nmotadas={nmotadas}
              />
            ))}
          </CardDeck>
        </Carousel.Item>
      </Carousel>
      <br /><br />
      <h3 id="empreText">EMPRESAS QUE CONFIAN EN NUESTRO CALZADO</h3>
      <br />
      <Carousel className="company" interval="2000" pause="false">
        <Carousel.Item>
          <CardGroup>
            <Card>
              <Card.Img variant="center" className="img-size" src={continental} />
            </Card>
            <Card>
              <Card.Img variant="center" className="img-size" src={honda} />
            </Card>
            <Card>
              <Card.Img variant="center" className="img-size" src={ibm} />
            </Card>
          </CardGroup>
        </Carousel.Item>
        <Carousel.Item>
          <CardGroup>
            <Card>
              <Card.Img variant="center" className="img-size" src={chedraui} />
            </Card>
            <Card>
              <Card.Img variant="center" className="img-size" src={panasonic} />
            </Card>
            <Card>
              <Card.Img variant="center" className="img-size" src={jabil} />
            </Card>
          </CardGroup>
        </Carousel.Item>
        <Carousel.Item>
          <CardGroup>
            <Card>
              <Card.Img variant="center" className="img-size" src={yakult} />
            </Card>
            <Card>
              <Card.Img variant="center" className="img-size" src={ingredion} />
            </Card>
            <Card>
              <Card.Img variant="center" className="img-size" src={estafeta} />
            </Card>
          </CardGroup>
        </Carousel.Item>
        <Carousel.Item>
          <CardGroup>
            <Card>
              <Card.Img variant="center" className="img-size" src={grupomar} />
            </Card>
            <Card>
              <Card.Img variant="center" className="img-size" src={abc} />
            </Card>
            <Card>
              <Card.Img variant="center" className="img-size" src={iusa} />
            </Card>
          </CardGroup>
        </Carousel.Item>
        <Carousel.Item>
          <CardGroup>
            <Card>
              <Card.Img variant="center" className="img-size" src={nippon} />
            </Card>
            <Card>
              <Card.Img variant="center" className="img-size" src={armasel} />
            </Card>
            <Card>
              <Card.Img variant="center" className="img-size" src={pinsa} />
            </Card>
          </CardGroup>
        </Carousel.Item>
        <Carousel.Item>
          <CardGroup>
            <Card>
              <Card.Img variant="center" className="img-size" src={esmeralda} />
            </Card>
            <Card>
              <Card.Img variant="center" className="img-size" src={verde} />
            </Card>
            <Card>
              <Card.Img variant="center" className="img-size" src={ibm} />
            </Card>
          </CardGroup>
        </Carousel.Item>
      </Carousel>
      <br />
    </div>
  );
}

export default Render;