import React from 'react';
import Producto from './Producto';


const MuestraImg = ({showImg}) => (
<div>
    {showImg.map(producto => (
            <Producto 
            key={producto.id}
            producto={producto}
            />
    ))}
</div>
);

export default MuestraImg;