import React from 'react';
import './industrial.css';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Botas from '../industrial/botas.jpg';
import BotaSombra from '../industrial/bota-con-sombra.jpeg';
import Indust from '../industrial/INDUSTRIALENCABEZADO.png';
import Redess from '../components/Redess';

const Industrial = () => {
  return (
    <div className="App">
      <Redess />
      <img src={Indust} alt="Industrial" />
      <br /><br />
      <Card body>Nuestros pies nos conectan con la tierra y nos mantienen en movimiento y constante desarrollo,
      es por eso que en Render creemos que el calzado de seguridad es un elemento indispensable para una empresa
 que busca incrementar sus niveles de producción y de sus utilidades bajo un entorno seguro y condiciones adecuadas para el empleado.</Card>
      <br /><br />
      <iframe class='videosre' width="560" height="315" src="https://www.youtube.com/embed/pj3PWmy6mHc?autoplay=1&mute=1&enable" frameborder="0" allow='autoplay; accelerometer encrypted-media 
      clipboard-write; gyroscope; picture-in-picture;' allowFullScreen title='Render Industrial' /><br /><br />
      <Container>
        <Row>
          <Col><img src={Botas} alt="botas-de-hule-calzado-seguridad-industrial" /></Col>
          <Col><span>¿Porque Confiar En Nosotros?</span><br /><br />
            <div>Tomamos en cuenta el presupuesto de la empresa, las condiciones de trabajo de los empleados,
            así como una serie de factores específicos de cada empresa para desarrollar un calzado adecuado
            que cumpla con los mejores estándares de seguridad, durabilidad y tecnología.
            En cuestión de seguridad antiestática somos referentes y los especialistas pues Render es una empresa que cuenta con el certificado tipo VII que avala nuestro calzado antiestático como el mejor y más confiable para las empresas del ramo electrónico, pues pasa por cada una de las pruebas de seguridad antiestática que establece la NOM (Norma Oficial Mexicana).
          </div>
          </Col>
        </Row>
      </Container>
      <br /><br />
      <Container>
        <Row>
          <Col><span>Calzado De Seguridad</span><br /><br />
            <div>Nuestro calzado de seguridad está enfocado en el uso profesional para todo tipo de industria y
            se encarga de proporcionar un nivel de protección superior. El calzado de seguridad es aquél
            que ofrece protección especial contra impactos en diferentes condiciones de trabajo</div>
          </Col>
          <Col><img id='botaindust' src={BotaSombra} alt="bota-con-sombra" /></Col>
        </Row>
      </Container>
      <br /><br />
    </div>
  );
}
export default Industrial;