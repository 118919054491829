import React from 'react';
import '../App.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Facebook from '../iconos-redes/facebook.png';
import Instagram from '../iconos-redes/instagram.png';
import Twitter from '../iconos-redes/twitter.png';
import Whatsapp from '../iconos-redes/whatsapp.png';
import logo from '../logo.png';
import logo3 from '../logo3.png';
import { Link } from 'react-router-dom';

const Header = () => {
  const closeIt = () => {
    let element2 = document.getElementById("responsive-navbar-nav");
    element2.classList.toggle("show");
  }
  return (
    <>
     <Navbar expand="lg" bg="" variant="dark" className="top-bar">
        <Container id="top-container">
          <Navbar.Brand >Envios gratis a todo México</Navbar.Brand>
          <Navbar.Brand >Somos fabricantes</Navbar.Brand>
          <Navbar.Brand >ventas@calzadorender.com</Navbar.Brand>
          <Navbar.Brand >Tel: (33) 31-21-99-15</Navbar.Brand>
          <Navbar.Brand >
            <a href="https://www.facebook.com/RenderCalzado" className="icoSize" target="_blank"><img src={Facebook} alt="logo" /></a>
            <a href="https://wa.me/5213329679793?text=%C2%A1Hola!%20Quiero%20solicitar%20informaci%C3%B3n%20sobre%20su%20calzado." className="icoSize" target="_blank"><img src={Whatsapp} alt="logo" /></a>
            <a href="https://www.instagram.com/calzadorender/" className="icoSize" target="_blank"><img src={Instagram} alt="logo" /></a>
            <a href="https://www.linkedin.com/company/calzado-render" className="icoSize" target="_blank"><img src={Twitter} alt="logo" /></a>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Navbar expand="lg" bg="" variant="dark" className="colorNav">
        <Navbar.Brand href=""><Link to="/" className="linkren"><img src={logo} className="logoSize" alt="logo" /></Link></Navbar.Brand>
        <Navbar.Brand className="mr-auto centNav">SEGURIDAD Y TECNOLOGÍA A TUS PIES</Navbar.Brand>
        <Navbar.Brand href="#home" className="logoSize floatRight"><img src={logo3} className="logoSize" alt="logo3" /></Navbar.Brand>
      </Navbar>
      <Navbar sticky="top" collapseOnSelect id="nav-padding" expand="lg" bg="dark" variant="dark">
        <Container>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" id="navye" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link onClick={closeIt}><Link to="/" className="linkren">Render</Link></Nav.Link>
              <NavDropdown title="Catalogo" id="collasible-nav-dropdown">
                <NavDropdown.Item href="CatIndustrial"><Link to="/CatIndustrial" className="linkren">Industrial</Link></NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="CatAntiestatico"><Link to="/CatAntiestatico" className="linkren">Antiestático</Link></NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="CatDielectrico"><Link to="/CatDielectrico" className="linkren">Dieléctrico</Link></NavDropdown.Item>
              </NavDropdown>
              <Nav.Link onClick={closeIt} collapseOnSelect><Link to="/Industrial" className="linkren">Seguridad Industrial</Link></Nav.Link>
              <Nav.Link onClick={closeIt}><Link to="/Antiestatico" className="linkren">Tecnología Antiestática</Link></Nav.Link>
              <Nav.Link onClick={closeIt}><Link to="/Contacto" className="linkren">Contacto</Link></Nav.Link>
              <Nav.Link href="https://renderantiestaticoeindustri.mercadoshops.com.mx/" className="linkren" target="_blank">Tienda</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;