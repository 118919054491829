import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';

const Producto = ({ producto, nomontadas, showImg, notShowImg }) => {

    const { name, imgN, index, desc } = producto;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // const selecImg = index => {
    //     const imagn = nomontadas.filter(imagn => imagn.index === index)[0];
    //     //testeando este imgN[0] = imagn.index;
    //     console.log(imagn);
    //     /*notShowImg([producto]);*/
    // };

    const [toggle, setToggle] = useState(imgN[0]);

    return (
        <>
            <Card border="primary" style={{ width: '18rem' }}>
                <a href="#top-container">
                    <Card.Img variant="top" onClick={handleShow} src={imgN[0]} />
                </a>
                <Card.Body>
                    <Card.Title>{name}</Card.Title>
                    <Button variant="primary" onClick={handleShow}>Ver Imagenes</Button>
                </Card.Body>
            </Card>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{name}</Modal.Title>
                </Modal.Header>
                    <Card.Img variant="top" src={toggle} />
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col sm>
                                <Image src={imgN[0]} rounded onClick={() => setToggle(imgN[0])} />
                            </Col>
                            <Col sm>
                                <Image src={imgN[1]} rounded onClick={() => setToggle(imgN[1])} />
                            </Col>
                            <Col sm>
                                <Image src={imgN[2]} rounded onClick={() => setToggle(imgN[2])} />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <div>
                <hr></hr>
                </div>
                <Card.Text>
                    {desc}
                </Card.Text>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <a href={'https://wa.me/5213329679793?text=%C2%A1Hola!%20Quiero%20comprar%20%20el ' + name}
                        target="_blank" rel="noopener noreferrer"><Button variant="primary">Comprar</Button></a>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Producto;