import React, { Component } from 'react';
import './industrial.css';
import Credentials from './Credentials';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';

export class Mape extends Component {
    state = {
        showingInfoWindow: false,  // Hides or shows the InfoWindow
        activeMarker: {},          // Shows the active marker upon click
        selectedPlace: {}          // Shows the InfoWindow to the selected place upon a marker
    };

    onMarkerClick = (props, marker, e) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });

    onClose = props => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    render() {
        return (
            <div>
                <Map
                    google={this.props.google}
                    zoom={14}
                    className='mapStyles' initialCenter={
                        {
                            lat: 20.6666369,
                            lng: -103.4122297
                        }
                    }
                >
                    <Marker
                        onClick={this.onMarkerClick}
                        name={'Calzado Render'}
                    //title={'Drag me!'}
                    />
                    <InfoWindow
                        marker={this.state.activeMarker}
                        visible={this.state.showingInfoWindow}
                        onClose={this.onClose}
                    >
                        <div>
                            <h4>{this.state.selectedPlace.name}</h4>
                            <a href="mailto:ventas@calzadorender.com">{'ventas@calzadorender.com'}</a><br /><br />
                            <a href="tel:3331219915">{'Teléfono: 33 31 21 99 15'}</a><br /><br />
                            <a href="https://goo.gl/maps/LhCtXaiUU2mTQfSd8">{'San Juan de La Cruz 637, Camino Real, 45040 Zapopan, Jal'}</a>
                        </div>
                    </InfoWindow>
                </Map>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: (Credentials.mapsKey)
})(Mape);