import React from 'react';
import './industrial.css';
import Mape from './Mape';

const Gracias = () => {
    
    return (
        <div id="contar">
            <div><br />
                    <h1>{'¡Gracias por contactarnos!'}</h1>
                    <a href="mailto:ventas@calzadorender.com">{'ventas@calzadorender.com'}</a><br />
                    <a href="tel:3331219915">{'Teléfono: 33 31 21 99 15'}</a><br />
                    <a href="https://goo.gl/maps/LhCtXaiUU2mTQfSd8" id='direccionren'>{'San Juan de La Cruz 637, Camino Real, 45040 Zapopan, Jal'}</a>
                </div>
                <br />
            <div className='centraGoogle'>
                <Mape />
            </div>
        </div>
    )
}

export default Gracias;