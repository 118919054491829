import React, { useState } from 'react';
import './industrial.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import cero1 from '../antiestatico/0011.jpg';
import cero2 from '../antiestatico/0012.jpg';
import cero3 from '../antiestatico/0013.JPG';
import sd1 from '../antiestatico/05-SD1.JPG';
import sd2 from '../antiestatico/05-SD2.JPG';
import sd3 from '../antiestatico/05-SD3.JPG';
import cero71 from '../antiestatico/007-AZUL1.JPG';
import cero72 from '../antiestatico/007-AZUL2.JPG';
import cero73 from '../antiestatico/007-AZUL3.JPG';
import cero91 from '../antiestatico/090-PSD1.JPG';
import cero92 from '../antiestatico/090-PSD2.JPG';
import cero93 from '../antiestatico/090-PSD3.JPG';
import cero51 from '../antiestatico/505-PSD.JPG';
import cero52 from '../antiestatico/505-PSD2.JPG';
import cero53 from '../antiestatico/505-PSD3.JPG';
import cero61 from '../antiestatico/603-PSD1.JPG';
import cero62 from '../antiestatico/603-PSD2.JPG';
import cero63 from '../antiestatico/603-PSD3.JPG';
import cero751 from '../antiestatico/705-PSD1.JPG';
import cero752 from '../antiestatico/705-PSD2.JPG';
import cero753 from '../antiestatico/705-PSD3.JPG';
import marca1 from '../antiestatico/705-MARCA1.jpg';
import marca2 from '../antiestatico/705-MARCA2.jpg';
import marca3 from '../antiestatico/705-MARCA3.jpg';
import psd1 from '../antiestatico/717-PSD1.jpg';
import psd2 from '../antiestatico/717-PSD2.jpg';
import psd3 from '../antiestatico/717-PSD3.jpg';
import sd71 from '../antiestatico/717-SD1.jpg';
import sd72 from '../antiestatico/717-SD2.jpg';
import sd73 from '../antiestatico/717-SD3.jpg';
import psd201 from '../antiestatico/720-PSD1.JPG';
import psd202 from '../antiestatico/720-PSD2.JPG';
import psd203 from '../antiestatico/720-PSD3.JPG';
import sd201 from '../antiestatico/720-SD1.jpg';
import sd202 from '../antiestatico/720-SD2.jpg';
import sd203 from '../antiestatico/720-SD3.jpg';
import CardDeck from 'react-bootstrap/CardDeck';
import Producto from './Producto';
import MuestraImg from './MuestraImg';
import AntiestImg1 from '../antiestatico/antiestatic.png';
import Redess from '../components/Redess';


const CatAntiestatico = () => {
    const [nomontadas, montadas] = useState([
        {index:1, imgN:[cero1,cero2,cero3], name:'001', desc:'Aquí va la descripción'},
        {index:2, imgN:[sd1,sd2,sd3], name:'05-SD', desc:'Aquí va la descripción'},
        {index:3, imgN:[cero71,cero72,cero73], name:'007-AZUL', desc:'Aquí va la descripción'},
        {index:4, imgN:[cero91,cero92,cero93], name:'090-PSD', desc:'Aquí va la descripción'}
    ]);
    const [nomotadas, motadas] = useState([
        {index:1, imgN:[cero51,cero52,cero53], name:'505-PSD', desc:'Aquí va la descripción'},
        {index:2, imgN:[cero61,cero62,cero63], name:'603-PSD', desc:'Aquí va la descripción'},
        {index:3, imgN:[cero751,cero752,cero753], name:'705-PSD', desc:'Aquí va la descripción'},
        {index:4, imgN:[marca1,marca2,marca3], name:'705-MARCA', desc:'Aquí va la descripción'}
    ]);
    const [nmotadas, mtadas] = useState([
        {index:1, imgN:[psd1,psd2,psd3], name:'717-PSD', desc:'El modelo 717 PSD, es un calzado antiestático tipo tenis. Elaborado con piel engrasada de gran resistencia y repelente al agua, cuenta con un diseño ergonómico que proporciona gran seguridad y comodidad al usuario. Esto convierte al 717 en el modelo perfecto para las electrónicas con condiciones de trabajo exigentes.'},
        {index:2, imgN:[sd71,sd72,sd73], name:'717-SD', desc:'Aquí va la descripción'},
        {index:3, imgN:[psd201,psd202,psd203], name:'720-PSD', desc:'Aquí va la descripción'},
        {index:4, imgN:[sd201,sd202,sd203], name:'720-SD', desc:'Aquí va la descripción'}
    ]);
    
    const [showImg, notShowImg] = useState([]);

    const seleccionarImg = index => {
    const imagn = nomontadas.filter(imagn => imagn.index === index)[0];
    montadas(imagn);
    };

    return (
        <div className="App">
            <Redess />
            <img src={AntiestImg1} alt="Antiestático" />
            <br /><br />
            <CardDeck className="catalocard">
            {nomontadas.map(producto => (
            <Producto 
            key={producto.id}
            producto={producto}
            nomontadas={nomontadas}
            showImg={showImg}
            notShowImg={notShowImg}
            />
            ))}
            </CardDeck>
            <br /><br />
            <MuestraImg 
            showImg={showImg}
            />
            <CardDeck className="catalocard">
            {nomotadas.map(producto => (
            <Producto 
            key={producto.id}
            producto={producto}
            nomotadas={nomotadas}
            />
            ))}
            </CardDeck>
            <br /><br />
            <CardDeck className="catalocard">
            {nmotadas.map(producto => (
            <Producto 
            key={producto.id}
            producto={producto}
            nmotadas={nmotadas}
            />
            ))}
            </CardDeck>
            <br /><br />
        </div>
    );
}

export default CatAntiestatico;