import React from 'react';
import '../App.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Facebook from '../iconos-redes/facebook.png';
import Instagram from '../iconos-redes/instagram.png';
import Twitter from '../iconos-redes/twitter.png';
import Whatsapp from '../iconos-redes/whatsapp.png';
import logo from '../logo.png';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';

const Footer = ({fecha}) =>  (
    <>
      <footer className="footerCol">
        <Row>
          <Col sm><Link to="/" className="linkren">RENDER</Link>
            <br /><br /><Link to="/Industrial" className="linkren">Seguridad Industrial</Link>
            <br /><br /><Link to="/Antiestatico" className="linkren">Tecnología Antiestática</Link>
            <br /><br /><Link to="/Contacto" className="linkren">Contacto</Link>
            <br /><br /><Nav.Link href="https://renderantiestaticoeindustri.mercadoshops.com.mx/" target="_blank" className="linkren">Tienda</Nav.Link></Col>
          <Col sm>Contactanos<br /><br />Email: ventas@calzadorender.com<br /><br />
      Teléfono: 33 31 21 99 15<br /><br />
      Dirección: San Juan de La Cruz 637 <br /> Camino Real 45040 Zapopan, Jal.</Col>
          <Col sm>Redes<br /><br />
            <a href="https://www.facebook.com/calzadorender" className="icoSize" target="_blank"><img src={Facebook} alt="logo" /></a>
            <a href="https://wa.me/5213329679793?text=%C2%A1Hola!%20Quiero%20solicitar%20m%C3%A1s%20informaci%C3%B3n%20sobre%20su%20calzado." className="icoSize" target="_blank"><img src={Whatsapp} alt="logo" /></a>
            <a href="https://www.instagram.com/calzadorender/" className="icoSize" target="_blank"><img src={Instagram} alt="logo" /></a>
            <a href="https://www.linkedin.com/company/calzado-render" className="icoSize" target="_blank"><img src={Twitter} alt="logo" /></a>
            <br /><br /><br />
            <img src={logo} className="logoSize" alt="logo" />
          </Col>
        </Row>
      </footer>
      <Nav className="justify-content-center app-header" activeKey="/home">
        <Nav.Item>
         <Nav.Link href="/home">Todos los derechos reservados RENDER © {fecha}</Nav.Link>
        </Nav.Item>
      </Nav>
    </>
  );

export default Footer;
