import React from 'react';
import '../App.css';

const Redess = () => {

  return (
    <>
        <ul class="home-social-list">
        <li class="animate-intro" id="move1"><span id="numberpc" class="phonebye">3329679793</span>
            <span class="fondo"><a href="tel:3329679793" id="numberph" target="_blank"><i
                        class="fas fa-phone"></i></a></span>
        </li>
        <li class="animate-intro" id="move2">
            <span class="fondo"><a
                    href="https://wa.me/5213329679793?text=%C2%A1Hola!%20Quiero%20solicitar%20informaci%C3%B3n%20sobre%20su%20calzado."
                    target="_blank"><i class="fab fa-whatsapp"></i></a></span>
        </li>
        <li class="animate-intro" id="move3">
            <span class="fondo"><a href="https://m.me/RenderCalzado" target="_blank"><i
                        class="fab fa-facebook-messenger"></i></a></span>
        </li>
        </ul>
    </>
  );
}

export default Redess;